var stickyHeader = true;
var flyoutNav = true;
// var fullscreenNav = true;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = true;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
  // Header -----------------
  // Replicate nav links
  replicateNavLinks();
  // Replicate main CTA buttons
  replicateMainCTAButtons();
  // Update search placeholder
  updateSearchPlaceholder();

  // Footer -----------------
  // Adjust layout for contact and socials
  adjustFooterContactSocials();
  // Move the copyright block outside
  adjustFooterCopyright();

  // Homepage ---------------
  // Adjust the impact stats section
  adjustImpactStats();
  // Adjust the CTA custom section
  adjustCustomCTASection();
  // Initiate News Feed
  initCarousel(
    "NewsFeed",
    "Explore all stories",
    "/Pages/News/Category/news"
  );
  // Initiate Events Feed
  initCarousel(
    "EventsFeed",
    "Explore what's on",
    "/Pages/Events/Category/events"
  );
  // Append Events Feed to its own section
  appendFeedTitle("NewsFeed", "homefeaturecategory-newstitle");
  // Append Events Feed to its own section
  appendFeedTitle("EventsFeed", "homefeaturecategory-eventstitle");
  // Append Welcome Feed to its own section
  appendFeedTitle("WarmWelcomeFeed", "homefeaturecategory-welcometitle");

  // Others -----------------
  // Adjust listing intro positions
  adjustListingIntro();
  // Adjust breadcrumb with proper links
  adjustBreadcrumbs();
  // Adjust FAQ image position
  adjustFAQImagePosition();
});

function adjustFAQImagePosition() {
  // Check if any FAQ images exists first
  if ($(".faqListingPage .faqAnswer .content > img").length) {
    // Move any FAQ image inside their own header box
    $(".faqListingPage .listedFaq").each(
      function() {
        // Select each faq image then
        let faqImage = $(this).find(".faqAnswer .content > img");
        // Wrap with a div class for styling
        faqImage.wrap('<div class="faqBanner"></div>');
        // Reselect the new wrapper class
        let faqBanner = $(this).find(".faqAnswer .faqBanner");
        // Select the header box as well
        let faqHeader = $(this).find(".accordianHeader");
        // Then move image banner to start of the header box
        faqBanner.prependTo(faqHeader);
      }
    );
  }
}

// Removing the duplicate Home title on Our Staff //
document.addEventListener("DOMContentLoaded", function() {
  var breadcrumbItem = document.querySelector('li.breadcrumbItem.breadcrumbLink > a[href="/"]');
  if (breadcrumbItem) {
      breadcrumbItem.closest('li').remove();
  }
});

function adjustBreadcrumbs() {
  // Edit breadcrumb with home and other links
  if ($(".breadcrumbWrapper").length) {
    var breadcrumbList = $(".breadcrumbWrapper .breadcrumbList");
    var breadcrumbHome = $('<li class="breadcrumbItem breadcrumbHome"><a href="/">Home</a></li>');
    breadcrumbHome.prependTo(breadcrumbList);
  }
}

function adjustListingIntro() {
  // Move listing intro description out to below with the content
  if ($(".listingIntro").length) {
    $(".listingIntro").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".listingIntro").prependTo(".appealListingPage .donorsListWrapper");
    $(".listingIntro").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }
}

function adjustCustomCTASection() {
  // Reference the CTA title + buttons sections
  const title = $(".homefeaturecategory-homectatitle");
  const button = $(".homefeaturecategory-homectabuttons");
  // Wrap the CTA title with a div element
  title.wrapInner('<div class="title-wrapper"></div>');
  // Move the CTA button into the title section
  button.appendTo(title);
}

function adjustImpactStats() {
  // Reference the image + blurb + stats sections
  const image = $(".homefeaturecategory-homeboximpactimage");
  const blurb = $(".homefeaturecategory-homeboximpactblurb");
  const stats = $(".homefeaturecategory-homeboximpactstats");
  // Wrap the image with a div element
  image.wrapInner('<div class="image-wrapper"></div>');
  // Move the blurb into the stats section
  blurb.prependTo(stats);
  // Move the image stats into the image section
  stats.appendTo(image);
}

function appendFeedTitle(feed, container) {
  // Get the feed carousel and feed title
  let carousel = $(`.homeFeed.${feed}`);
  let titleSection = $(`.${container}`);
  // Wrap the title content with a div
  titleSection.wrapInner('<div class="titleWrapper"></div>');
  // Then move the carousel inside the title section
  carousel.appendTo(titleSection);
}

function removeHomeCarouselLinks() {
  // For each home carousel pages
  $("body.homepage .carouselImageMultiple #carouselSlides .carouselSlide").each(
    function () {
      // Remove any reference of linking to said page
      $(this).find("a").removeAttr("href").removeAttr("title");
      $(this).find(".carouselSlideDetail").attr("data-url", "#");
    }
  );
}

function initCarousel(feed, ctaText, ctaURL) {
  // Set the carousel selector
  let carousel = ".homeFeed." + feed;
  // If carousl exists on page
  if ($(`${carousel}`).length) {
    // Adjust the feed details first
    adjustFeedDetails(carousel);
    // Create the slider arrows + dots + cta
    let elements = createSliderElements(ctaText, ctaURL);
    // Then append under the carousel
    $(`${carousel}`).append(elements.navigator);
    // Init slick slider
    $(`${carousel} .feedList`).slick({
      dots: true,
      arrows: true,
      appendDots: $(`${carousel} .dot-wrapper`),
      prevArrow: $(`${carousel} .prev-slide`),
      nextArrow: $(`${carousel} .next-slide`),
      fade: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
}

function adjustFeedDetails(carousel) {
  // for each feed item present
  $(`${carousel} .feedItem`).each(
    function () {
      // Set reference to both footer and details wrapper of feed
      let footerWrapper = $(this).find(".postFooterWrapper");
      let detailsWrapper = $(this).find(".feedItemDetailsWrapper");
      // Move the footer wrapper to the details wrapper
      footerWrapper.prependTo(detailsWrapper);
    }
  );
}

function createSliderElements(ctaText, ctaURL) {
  let prevArrow = '<button type="button" class="prev-slide"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>';
  let nextArrow = '<button type="button" class="next-slide"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>';
  let dotCustom = '<div class="dot-wrapper"></div>';
  let sliderNav = '<div class="slider-nav">' + prevArrow + dotCustom + nextArrow + "</div>";
  let slideMore = '<p class="slider-label">Slide for more</p>';
  let sliderBox = '<div class="slider-box">' + slideMore + sliderNav + "</div>";
  let ctaButton = '<a class="button" href="' + ctaURL + '" aria-label="' + ctaText + '">' + ctaText + "</a>";
  let navigator = '<div class="navigator">' + sliderBox + ctaButton + "</div>";
  return {
    prevArrow,
    nextArrow,
    dotCustom,
    sliderNav,
    slideMore,
    sliderBox,
    navigator,
  };
}

function adjustFooterCopyright() {
  // Reference the footer parent
  const footerParent = $("#pageFooterWrapper");
  // Reference the copyright block
  const footerCopyright = $("#pageFooterWrapper .FooterCopyright");
  // Move it outside the default wrapper
  footerCopyright.appendTo(footerParent);
}

function adjustFooterContactSocials() {
  // Reference the footer contact
  const footerContact = $("#pageFooterWrapper .FooterContact");
  // Reference the socials block
  const footerSocials = $("#pageFooterWrapper .FooterSocials");
  // Encapsulate contact block with a wrapper element
  footerContact.wrapInner('<div class="wrapper"></div>');
  // Move socials block to the contact block
  footerSocials.appendTo(footerContact);
}

function updateSearchPlaceholder() {
  // Reference the search bar on the main menu
  var searchInput = $("#pageHeader #menuMain .header-search .searchContainer input");
  // Replace the search bar placeholder text
  searchInput.prop("placeholder", "Search Site");
}

function replicateMainCTAButtons() {
  // Reference the burger menu, the fly off menu and main CTA buttons
  const menuMain = $("#menuMain");
  const burgerMenu = $("#menuMain .menuMainAlt");
  const headerLogo = $("#pageHeader .headerContent .mainLogo");
  const mainCallToActionButtons = $("#pageHeader #menuMain .mainCallToActionButtons");
  // Create a new parent element
  var interactMenu = $('<div id="interactMenu"></div>');
  var logoBurgerWrapper = $('<div class="logo-burger"></div>');
  // Clone site logo and move to the new wrapper element
  headerLogo.clone().appendTo(logoBurgerWrapper);
  // And also the burger menu
  burgerMenu.appendTo(logoBurgerWrapper);
  // Append the CTA buttons to the new parent element
  mainCallToActionButtons.appendTo(interactMenu);
  // Move to the first top level
  interactMenu.prependTo(menuMain);
  // And also the new wrapper
  logoBurgerWrapper.appendTo(interactMenu);
}

function replicateNavLinks() {
  // Reference the main call to action buttons on visible menu
  const mainCallToActionButtons = $("#pageHeader .headerContent .mainCallToActionButtons");
  // Select the first three list items
  var firstThreeNavs = $("#menuMain .topLevel .level1").slice(0, 3);
  // Create a new parent element
  var replicaMenu = $('<div id="replicaMenu"></div>');
  // Clone them and append the clones to the new parent element
  firstThreeNavs.clone().appendTo(replicaMenu);
  // Then move it after main call to action buttons
  replicaMenu.insertAfter(mainCallToActionButtons);
}
